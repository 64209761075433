import React from 'react'
import Layout from '../layout/basic.js'

import '../css/philosophy/style.scss'
import "animate.css/animate.min.css";

import ScrollAnimation from 'react-animate-on-scroll';

const PhilosophyPage = () => (
  <Layout currentName="philosophy">
    <section className="philosophy">

      <article className="hero header-hero has-text-centered">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">企業理念</h1>
            <h2 className="subtitle">PHILOSOPHY</h2>
          </div>
        </div>
      </article>

      <article className="main-contents">
        <div className="container columns is-multiline">
          <div className="column is-6 is-offset-3">
            <div className="card">
              <div className="card-content">
                <div className="content">
                  <p>
                    我々の使命とは、
                    <span className="laud-text marked-by-coveredline marker-is-red">
                      デザインにより価値を最大化したソリューションを提供して人類の発展に貢献すること
                    </span>
                    です。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="description column is-6 is-offset-3">
            <p>
              ソリューションとは、問題や課題に対する解決策のこと。
            </p>
            <p>
              様々な通信網で繋がり、ＩＴなどの技術により世界が一つの共同体を形成している現代、
              人類は絶えず様々なソリューション（解決策）を生み続けています。
              しかしどれほど有益であっても、人々に受け入れられないものが少なくありません。
              受け入れられるか否かを分かつものは何か？
              我々がたどり着いた答え、それがデザインでした。
            </p>
            <p>
              適切にデザインされていないソリューションは人々に届きません。
              人々に届かなければ、ソリューションの存在価値はありません。
              我々は単に技術を使いソリューションを提供するだけではなく、そこにデザインを加えることで、
              価値を最も届けられる形にしたソリューションを提供して、その成果により人類の発展に貢献することを使命としています。
            </p>
          </div>

            <div className="bridge column is-4 is-offset-4 has-text-centered">
              <ScrollAnimation animateIn="fadeIn"
                delay={100}
                animateOnce={true}>
                <p>
                  そして我々は、この使命を遂げるため、３つの信念を支えとしています。
                </p>
              </ScrollAnimation>
            </div>
            <div className="column is-4"></div>

            <div className="column is-4">
              <ScrollAnimation animateIn="fadeIn"
                delay={100}
                animateOnce={true}>
                <div className="card">
                  <div className="card-content">
                    <div className="header has-text-centered">
                      <h2>
                        <span className="laud-text marked-by-underline marker-is-green">
                          日々の積み重ねを尊ぶ
                        </span>
                      </h2>
                    </div>
                    <div className="description content">
                      <p>
                        ＩＴなどの技術を適切にデザインする上で最も必要なこと。
                      </p>
                      <p>
                        それは一つ一つ小さな作物を拾うように、日々、地道に小さな研鑽を行うことです。
                      </p>
                      <p>
                        その積み重ねが技術を我がものにして、適切なデザインを生み出す源になると信じています。
                      </p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="column is-4">
              <ScrollAnimation animateIn="fadeIn"
                delay={300}
                animateOnce={true}>
                <div className="card">
                  <div className="card-content">
                    <div className="header has-text-centered">
                      <h2>
                        <span className="laud-text marked-by-underline marker-is-green">
                          文化に根付くものを尊ぶ
                        </span>
                      </h2>
                    </div>
                    <div className="description content">
                      <p>
                        現代は流行が生まれては消え、
                        次々に持てはやされるものが変わる時代です。
                      </p>
                      <p>
                        しかしより尊ぶべきは、文化に根付き、
                        人々に明日を生きる（活きる）糧を与えてくれる存在です。
                      </p>
                      <p>
                        我々がデザインするものは、そのような存在であるべきです。
                      </p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

            <div className="column is-4">
              <ScrollAnimation animateIn="fadeIn"
                delay={500}
                animateOnce={true}>
                <div className="card">
                  <div className="card-content">
                    <div className="header has-text-centered">
                      <h2>
                        <span className="laud-text marked-by-underline marker-is-green">
                          貢献する人を尊ぶ
                        </span>
                      </h2>
                    </div>
                    <div className="description content">
                      <p>
                        我々は、我々とは別の誰かに貢献するために存在します。
                      </p>
                      <p>
                        しかし効率だけを求めれば、その存在を軽視することになりかねません。
                      </p>
                      <p>
                        その存在を忘れず、尊ぶこと。
                        その思いが我々のデザインに大きな力を与えてくれると信じています。
                      </p>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>
            </div>

          <div className="bridge column is-4 is-offset-4 has-text-centered">
            <p>これらの想いは社名やロゴにも取り入れられ、
                文字通り当社の根幹を支えています。</p>
          </div>
        </div>
      </article>

    </section>
  </Layout>
);
export default PhilosophyPage;
